import React, { useState, useEffect } from 'react';
import api from '../api';
import FirmlingItem from '../components/FirmlingItem';
import EditFirmlingModal from '../components/EditFirmlingModal';
import FirmDetailsModal from '../components/FirmDetailsModal';
import '../styles/ManageFirmlinge.css';

const FirmlingeList = () => {
    const [firmlinge, setFirmlinge] = useState([]);
    const [selectedFirmling, setSelectedFirmling] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showFirmDetailsModal, setShowFirmDetailsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userRoles, setUserRoles] = useState([]); // Zustandsvariable für die Benutzerrollen

    useEffect(() => {
        // Rollen aus dem localStorage laden
        const roles = JSON.parse(localStorage.getItem('userRoles')) || [];
        setUserRoles(roles);
        loadFirmlinge();
    }, []);

    const loadFirmlinge = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/api/firmlinge');
            setFirmlinge(response.data);
        } catch (error) {
            console.error('Error loading Firmlinge:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = (firmling) => {
        setSelectedFirmling(firmling);
        setShowEditModal(true);
    };

    const handleDetailsClick = (firmling) => {
        setSelectedFirmling(firmling);
        setShowFirmDetailsModal(true);
    };

    const handleDeleteClick = async (firmlingId) => {
        if (window.confirm('Möchten Sie diesen Firmling wirklich löschen?')) {
            try {
                await api.delete(`/api/deleteFirmling/${firmlingId}`);
                loadFirmlinge();
            } catch (error) {
                console.error('Error deleting Firmling:', error);
            }
        }
    };

    return (
        <div className="manage-activity-container">
            <h2 className="mb-4">Firmlinge Verwaltung</h2>
            {isLoading ? (
                <div className="loading">Lade Firmlinge...</div>
            ) : (
                <ul className="manage-firmling-list-scroll scroll-big">
                    {firmlinge.map(firmling => (
                        <FirmlingItem
                            key={firmling.id}
                            firmling={firmling}
                            onEdit={() => handleEditClick(firmling)}
                            onDetails={() => handleDetailsClick(firmling)}
                            onDelete={() => handleDeleteClick(firmling.id)}
                            userRoles={userRoles} // Benutzerrollen als Prop an FirmlingItem übergeben
                        />
                    ))}
                </ul>
            )}
            {showEditModal && (
                <EditFirmlingModal
                    firmling={selectedFirmling}
                    onClose={() => setShowEditModal(false)}
                    reloadList={loadFirmlinge}
                />
            )}
            {showFirmDetailsModal && (
                <FirmDetailsModal
                    firmlingId={selectedFirmling.id}
                    onClose={() => setShowFirmDetailsModal(false)}
                />
            )}
        </div>
    );
};

export default FirmlingeList;
